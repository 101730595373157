import React, { useState, useEffect } from 'react';
import './advertising-header.scss';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';

export default function AdvertisingHeader({ data }) {

    const dispatch = useDispatch();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        dispatch({ type: 'TOGGLE_ADVERTISING_DATA', value: false });
        setShow(false);
    }

    return (
        show ?
            <div className="advertising-header">
                <Alert variant="dark" className="container-app" onClose={handleClose} dismissible>
                    {
                        data.title
                    }
                    {
                        data.link !== undefined &&
                        <Button as={Link} to="/" variant="dark" size="sm" className="ml-3">
                            {data.link_text || "Lire plus"}
                        </Button>
                    }

                </Alert>
            </div>
            :
            <></>

    )
}