
const initialState = {
    shown: true,
    id: undefined,
    title: undefined,
    description: undefined,
    media_url: undefined,
    link: undefined,
    link_text: undefined,
    prefix: undefined,
    type: undefined,
    expirable: undefined,
    expire_date: undefined,
    creation_time: undefined,
}

export default function handleAdvertisingReducer(state = initialState, action) {

    let nextState;

    switch (action.type) {
        case 'SET_ADVERTISING_DATA':
            nextState = {
                ...state,
                shown: true,
                ...action.value
            }
            return nextState || state;
        case 'TOGGLE_ADVERTISING_DATA':
            nextState = {
                shown: action.value
            }
            return nextState || state;
        default:
            return state;
    }
}