import React, { useState, useEffect, useRef } from 'react';
import './beta.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import { HashLink as Link } from 'react-router-hash-link';

export default function OurIdentity() {

    const [percent, setParcent] = useState(0);
    const timer = useRef();

    useEffect(() => {
        if (percent < 100) {
            setTimeout(() => setParcent(percent + 1), 10);
        }
    }, [percent]);


    return (
        <Col sm="12" className="our-identity-beta container-app">
            <Row>
                <Col sm="12">
                    <Row className="bref" id="bref">
                        <Col sm="12">
                            <h1>CASH en bref</h1>
                        </Col>
                        <Col sm="8">
                            <p>
                                A capitaux 100% publics, dont SONATRACH, première entreprise Africaine, est l’actionnaire majoritaire, CASH, fondée en 1996 à la faveur de l’ordonnance 95-07 et entrée en activité en 1999, est une compagnie d’assurances dommages fidèle à l’image qu’elle se doit d’incarner : un assureur moderne, fiable et agile.<br /><br />

                        Le standing des sociétés composant son actionnariat lui a imprimé, dès sa création, une ligne de conduite marquée par la recherche constante de l’excellence, notamment dans la prise en charge de risques particulièrement complexes.La CASH est aujourd’hui leader Algérien dans la couverture des risques nécessitant un haut degré de technicité et la deuxième plus grande compagnie algérienne dans la couverture des risques d’entreprises et les risques liés au transport.<br /><br />

                        En plus de la consolidation de son leadership dans le segment des risques majeurs, CASH Assurance a élargi ses offres pour inclure la couverture des risques liés aux professions libérales et aux particuliers, à travers des garanties qui conviennent à chaque catégorie et ce pour l’ensemble des risques de masse relevant du train de vie de l’assuré.<br /><br />

                            </p>
                        </Col>
                        <Col className="right-side" sm="4">
                            <span>{`${percent}%`}</span>
                            <br />
                            <h4>ORIENTEE CLIENT</h4>

                        </Col>
                    </Row>
                    <Row className="history" id="history">
                        <Col sm="12">
                            <h1>Histoire</h1>
                            <h2>UNE HISTOIRE DE DEVELOPPEMENT PROSPERE</h2>
                            <h2><span className="bg-grey">20</span> Milliards de DA<br /></h2>
                            <h2><span>100%</span> capitaux publics</h2>
                        </Col>
                        <Col className="mt-5" sm="12">

                        <Row>
                                <Col className="card-container" lg={{ span: 3, offset: 10 }} md="6" sm="12">
                                    <Card>
                                        <Card.Body>
                                            Rentabilité record
                                            et position consolidée
                                </Card.Body>
                                        <Card.Footer>
                                            <span>2022</span>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>


                        <Row>
                                <Col className="card-container" lg={{ span: 3, offset: 8 }} md="6" sm="12">
                                    <Card>
                                        <Card.Body>
                                            Performances solides
                                            et croissances soutenues
                                </Card.Body>
                                        <Card.Footer>
                                            <span>2021</span>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>


                            <Row>
                                <Col className="card-container" lg={{ span: 3, offset: 6 }} md="6" sm="12">
                                    <Card>
                                        <Card.Body>
                                            Contexte inédit
                                            résultats records hausse du capital social

                                </Card.Body>
                                        <Card.Footer>
                                            <span>2020</span>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="card-container" lg={{ span: 3, offset: 4 }} md="6" sm="12">
                                    <Card>
                                        <Card.Body>
                                            Création de AGLIC « filiale assurance de personnes »
                                </Card.Body>
                                        <Card.Footer>
                                            <span>2015</span>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="card-container" lg={{ span: 3, offset: 2 }} md="6" sm="12">
                                    <Card>
                                        <Card.Body>
                                            Hausse du capital social stratégie de diversification
                                </Card.Body>
                                        <Card.Footer>
                                            <span>2007</span>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="card-container" lg={{ span: 3, offset: 0 }} md="6" sm="12">
                                    <Card>
                                        <Card.Body>
                                            Le 04 octobre, la CASH Assurances entre en activité
                                </Card.Body>
                                        <Card.Footer>
                                            <span>1999</span>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="point" id="point">
                        <Col sm="12">
                            <h1>Points de repère</h1>
                            <h2>C’EST ÉGALEMENT UNE</h2>
                            <h2><strong>VISION INCLUSIVE</strong> ET</h2>
                            <h2>DES <strong>VALEURS SURES</strong></h2>
                        </Col>
                        <Col sm="12">
                            <Row>
                                <Col lg="4" sm="12">
                                    <Card>
                                        <Card.Header>
                                            <h3><span className="icon-mission"></span>MISSION</h3>
                                        </Card.Header>
                                        <Card.Body>
                                            <p className="bg-cash">
                                                VOUS AIDER À PROTÉGER CE QUI COMPTE POUR VOUS.
                                        <br /><br />
                                        CONSEIL-ACCOMPAGNEMENT-SOLUTIONS
                                    </p>
                                            <p>
                                                La CASH intervient dans trois domaines d’activité : les risques de pointe, les risques des entreprises de toutes tailles et secteurs d’activité et ceux des particuliers et des professionnels.<br /><br />

                                        Nous proposons à nos clients une large gamme de garanties adaptées à toutes sortes d’activités et à différentes tailles de business pour leur offrir une couverture optimale à des prix judicieusement étudiés.

                                    </p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="4" sm="12">
                                    <Card>
                                        <Card.Header>
                                            <h3><span className="icon-vision"></span>VISION</h3>
                                        </Card.Header>
                                        <Card.Body>
                                            <p className="bg-cash">
                                                UNE ORIENTATION GUIDÉE PAR LE BIEN- ÊTRE DE NOS CLIENTS ET PAR LES DÉFIS DE DEMAIN
                                        <br /><br />
                                        LEADER DE QUALITE ET D’INNOVATION
                                    </p>
                                            <p>
                                                Dans un secteur qui évolue de plus en plus vite, CASH accentue sa détermination à asseoir définitivement les bases de sa pérennité commerciale.<br /><br />

                                        En plus de la consolidation de sa position de Leader des risques de pointe, la CASH aspire à développer un portefeuille d’affaires au titre des risques d’entreprises et des professionnels diversifié et profitable et à pérenniser des croissances rentables de ses activités
                                    </p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="4" sm="12">
                                    <Card>
                                        <Card.Header>
                                            <h3><span className="icon-valeur"></span>VALEURS</h3>
                                        </Card.Header>
                                        <Card.Body>
                                            <p className="bg-cash">
                                                DES PRINCIPES DIRECTEURS IMMUABLES QUI FONT NOTRE FORCE
                                         <br /><br />
                                        DES POINTS DE REPERES INCULQUÉS A TOUS NOS COLLABORATEURS
                                    </p>
                                            <p>
                                                La compagnie prône avec force les principes fondamentaux de respect des engagements, d’ouverture et de transparence.
                                        <br /><br />
                                        La CASH œuvre au quotidien à demeurer accessible et à l’écoute de ses clients, de son environnement et aux idées nouvelles.
                                        <br /><br />
                                        Nous mettons un point d’honneur à être à la hauteur des attentes de notre bien le plus précieux : nos Clients.

                                    </p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="key-figures" id="key-figures">
                        <Col sm="12">
                            <h1>Chiffres clés</h1>
                            <h2>NOTRE COMPAGNIE EN</h2>
                            <h2>QUELQUES <strong>CHIFFRES</strong></h2>
                        </Col>
                        <Col sm="12">
                            <Row className="mt-5 mb-5 justify-content-around">
                                <Col sm="auto">
                                    <FigureKeyCardRounded
                                        title="CAPITAL SOCIAL"
                                        number={20}
                                        content="milliards DA"
                                        color="#f5b8b8"
                                    />
                                </Col>
                                <Col sm="auto">
                                    <FigureKeyCardRounded
                                        title="Marge de solvabilité"
                                        number={20.9}
                                        content="milliards DA"
                                        color="#f5b8b8"
                                    />
                                </Col>
                                <Col sm="auto">
                                    <FigureKeyCardRounded
                                        title="Actifs financiers"
                                        number={32}
                                        content="milliards DA"
                                        color="#f5b8b8"
                                    />
                                </Col>
                                <Col sm="auto">
                                    <FigureKeyCardRounded
                                        title="Clients"
                                        number={37000}
                                        content="+12%"
                                        color="#f5b8b8"
                                    />
                                </Col>
                                <Col sm="auto">
                                    <FigureKeyCardRounded
                                        title="Chiffres d'affaires"
                                        subTitle="+6% en 2023"
                                        number={20}
                                        content="milliards DA"
                                        color="#f5b8b8"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="12" className="title-bottom">
                            <Row>
                                <Col sm="auto">
                                    <h2 className="border-left pl-3"><span className="bg-grey">05</span> directions régionales</h2>
                                </Col>
                                <Col className="ml-5" sm="auto">
                                    <h2 className="border-left pl-3">
                                        <span className="bg-grey">46</span> agences commerciales <br />
                                        dans <span>27</span> wilaya du pays
                                    </h2>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="mt-5" sm="12">
                            <h2><strong>LEADER</strong> DE <strong>DE QUALITE</strong></h2>
                            <h2>ET <strong>D’INNOVATION</strong></h2>
                        </Col>
                        <Col sm="12">
                            <Row className="mt-5 mb-5 justify-content-around">
                                <Col sm="auto">
                                    <FigureKeyCardRounded
                                        title="Marge nette"
                                        number={3.43}
                                        content="milliards DA"
                                        color="#ffd7d9"
                                    />
                                </Col>
                                <Col sm="auto">
                                    <FigureKeyCardRounded
                                        title="Résultat net"
                                        number={1.68}
                                        content="milliards DA"
                                        color="#ffd7d9"
                                    />
                                </Col>
                                <Col sm="auto">
                                    <FigureKeyCardRounded
                                        title="Part de marché"
                                        subTitle="(hors automobile)"
                                        number={24}
                                        symbole="%"
                                        content="(+2.5pts%)"
                                        color="#ffd7d9"
                                    />
                                </Col>
                                <Col sm="auto">
                                    <FigureKeyCardRounded
                                        title="Indemnisations"
                                        subTitle="(15 053 dossiers /an)"
                                        number={4.6}
                                        content="milliards DA"
                                        color="#ffd7d9"
                                    />
                                </Col>
                                <Col sm="auto">
                                    <FigureKeyCardRounded
                                        title="Rentabilité du capital social"
                                        subTitle="(des capitaux propres)"
                                        number={11.2}
                                        symbole="%"
                                        content="(8.1 %)"
                                        color="#ffd7d9"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="12" className="title-bottom">
                            <Row>
                                <Col sm="auto">
                                    <h2 className="border-left pl-3">
                                        <span className="bg-grey">28.4</span> Millards DA<br />
                                        d'indemnisation au cours des 05<br />
                                        dernières années
                                    </h2>
                                </Col>
                                <Col className="ml-4" sm="auto">
                                    <h2 className="border-left pl-3">
                                        <span className="bg-grey">75.3 %</span> (12.8%) ratio<br />
                                        combiné brut (s/p)
                                    </h2>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="humain-capital" id="humain-capital">
                        <Col sm="12">
                            <h1>Capital Humain</h1>
                            <h2>673 COLLABORATEURS</h2>
                            <h2>AUX <strong>TALENTS DIVERSIFIES </strong></h2>
                        </Col>
                        <Col sm="12">
                            <img src="/cloud/luna/images-presentations/capital-humain.png" className="w-100" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}

function NavigationScroll() {

    return (
        <Nav className="navigation-scroll" activeKey="#bref">
            <Nav.Item>
                <Nav.Link as={Link} to="#bref">CASH en bref</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link}  to="#history">Histoire</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link}  to="#point">Points de repère</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link}  to="#key-figures">Chiffres clés</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link}  to="#humain-capital">Capital Humain</Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

function FigureKeyCardRounded({ title, subTitle, symbole, number, content, color }) {

    const [state, setState] = useState(0);
    const merge = useRef(parseFloat(((number * 2) / 100).toFixed(2)));
    const timer = useRef();
    const secondes = useRef(10);
    const lock = useRef(false);
    const ref = useRef();

    const isElementInViewport = (el) => {

        var rect = el.current.getBoundingClientRect();

        return (
            rect.top >= 250 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }

    const handleScroll = (e) => {
        if (isElementInViewport(ref) && !lock.current) {
            updateNumber();
            console.log(new Date)
        }
    }

    useEffect(() => {
        document.addEventListener("scroll", handleScroll, true);

        return () => {
            document.removeEventListener("scroll", handleScroll, true);
        }

    }, []);


    const updateNumber = () => {
        lock.current = true;
        if (state + merge.current < number) {
            setTimeout(() => setState(parseFloat((state + merge.current).toFixed(2))), secondes.current);
        } else {
            setState(number);
        }
    }

    useEffect(() => {
        console.log("start")
        if (number !== state && lock.current) {
            updateNumber();
        }
    }, [state]);

    return (
        <Card ref={ref} className="figure-key-card-rounded">
            <Card.Header>
                {
                    title &&
                    <h3>{title}</h3>
                }
                {
                    subTitle &&
                    <h4>{subTitle}</h4>
                }
            </Card.Header>
            <Card.Body>

                <div className="content" style={{ backgroundColor: color }}>
                    <h2>{`${state} ${symbole || ""}`}</h2>
                    <h4>{content}</h4>
                </div>
            </Card.Body>
        </Card>
    )
}
