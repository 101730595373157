const initialState = {}

export default function handleLunaFormsReducer(state = initialState, action) {

    let nextState;

    switch (action.type) {
        case 'LUNA_FORMS_UPDATE':
            nextState = {
                ...state,
                ...action.value
            }
            return nextState || state;
        case 'LUNA_FORMS_ITEMS_UPDATE':
            let nextStateFormName = {};
            nextStateFormName[action.value.formName] = { ...state[action.value.formName], ...action.value.value }
            nextState = {
                ...state,
                ...nextStateFormName
            }
            return nextState || state;
        case 'LUNA_FORMS_ITEMS_RESET':
            let resetedForm = {};
            resetedForm[action.value.formName] = action.value.data;
            nextState = {
                ...state,
                ...resetedForm
            }
            return nextState || state;
        default:
            return state;
    }
}