
const initalState = {}

export default function handleUiReducer(state = initalState, action){
    let nextState;

    switch (action.type) {
        case 'UI_CUSTOM':
            nextState = {
                ...state,
            }
            return nextState || state;
        default:
            return state;
    }
}