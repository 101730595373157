
import React, { useState, useEffect } from 'react';
import Navbar from './../navbar/navbar';
import Footer from './../footer/footer';
import ContactMessenger from './../../components/contact/contact-messenger/contact-messenger';
import AdvertisingHeader from './../../components/advertising/advertising-header/advertising-header';
import AdvertisingToast from './../../components/advertising/advertising-toast/advertising-toast';
import { useLocation } from 'react-router-dom';
import Notifications from './../../components/notifications/notifications';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import BackToTop from './../../components/back-to-top/back-to-top';
import QuoteCall from './../quote-call/quote-call';
import { TermsAndConditions } from './terms';

export default function Layout({ children, className, hasFooter = true }) {

    const location = useLocation();
    const dispatch = useDispatch();

    const advertisingData = useSelector(state => state.handleAdvertisingReducer);

    const getAdvertising = () => {
        axios.get(`${process.env.REACT_APP_API_PROXY}/api/Advertising/EXTERNAL_GetAdvertising`).then(result => {

            let json = result.data;
            if(Array.isArray(json) && json.length > 0){
                dispatch({ type: 'SET_ADVERTISING_DATA', value: json[0] });
            }
                
            

        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    }

    useEffect(() => {
        getAdvertising();
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [location])

    return (
     
        <div className={`App ${location !== undefined && location.pathname === "/" ? "transparent-layout" : ""} ${className || ""}`}>
            <TermsAndConditions />
            {
                advertisingData !== undefined && advertisingData.type === "Above" &&
                <AdvertisingHeader data={advertisingData} />
            }
            <header>
                <Navbar />
            </header>
            <main >
                <div className="col-12 h-100">
                    <div className="row h-100">
                        {children}
                    </div>
                </div>
            </main>
            {
                hasFooter &&
                <Footer />
            }
            {
                advertisingData !== undefined && advertisingData.type === "Toast" &&
                <AdvertisingToast data={advertisingData} />
            }
            {/*<AdvertisingModal data={advertising} />*/}
            <ContactMessenger />
            <Notifications />
            <BackToTop />
            <QuoteCall />
        </div>
    )

}

/*...({ className: (advertisingData !== undefined && advertisingData.shown) ? "expend" : undefined })*/
