
const initialState = {
    showNotification: false,
    status: undefined,
    title: undefined,
    message: undefined
}

export default function handleNotificationsReducer(state = initialState, action) {

    let nextState;

    switch (action.type) {
        case 'NEW_NOTIFICATION':
            nextState = {
                ...state,
                showNotification: true,
                title: action.value.title,
                status: action.value.status,
                message: action.value.message
            }
            return nextState || state;
        case 'RESET_NOTIFICATION':
            nextState = {
                ...state,
                showNotification: false,
                title: undefined,
                status: undefined,
                message: undefined
            }
            return nextState || state;
        default:
            return state;
    }
}