import React, { useEffect, useState } from 'react';
import './back-to-top.scss';
import Button from 'react-bootstrap/Button';

export default function BackToTop() {

    const [show, setShow] = useState(false);

    useEffect(() => {
        
        document.addEventListener("scroll", e => {
            if (document.scrollingElement.scrollTop < 80) {
                setShow(false);
            } else {
                if(!show){
                    setShow(true);
                }
            }
        });

    }, [])

    const handleOnClick = (e) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <>
            {
                show &&
                <Button type="button" onClick={handleOnClick} variant="link" className="back-to-top"></Button>
            }
        </>
    )
}