
const initialState = {
    attempDescription: 0,
    attempInformation: 0,
    currentStep: 0,
    piqueStep: 0,
    dataQuote: undefined,
    condtionsAccept: false,
    recaptchaAccept: false,
    isInvalid: false
}

export default function handleQuoteHomeReducer(state = initialState, action) {

    let nextState;

    switch (action.type) {
        case 'QUOTE_HOME_DATA':
            nextState = {
                ...state,
                dataQuote: action.value
            }
            return nextState || state;
        case 'QUOTE_HOME_ATTEMP_DESCRIPTION':
            nextState = {
                ...state,
                attempDescription: action.value
            }
            return nextState || state;
        case 'QUOTE_HOME_ATTEMP_INFORMATION':
            nextState = {
                ...state,
                attempInformation: action.value
            }
            return nextState || state;
        case 'QUOTE_HOME_CURRENT_STEP':
            nextState = {
                ...state,
                currentStep: action.value,
                piqueStep: action.value > state.piqueStep ? action.value : state.piqueStep
            }
            return nextState || state;
        case 'QUOTE_HOME_CONDITIONS_ACCEPT':
            nextState = {
                ...state,
                condtionsAccept: action.value
            }
            return nextState || state;
        case 'QUOTE_HOME_RECAPTCHA_ACCEPT':
            nextState = {
                ...state,
                recaptchaAccept: action.value
            }
            return nextState || state;
        case 'QUOTE_HOME_LAST_STEP_ISINVALID':
            nextState = {
                ...state,
                isInvalid: action.value
            }
            return nextState || state;
        case 'QUOTE_HOME_RESET':
            nextState = {
                attempDescription: 0,
                attempInformation: 0,
                currentStep: 0,
                piqueStep: 0,
                dataQuote: undefined,
                condtionsAccept: false,
                recaptchaAccept: false,
                isInvalid: false
            }
            return nextState || state;
        default:
            return state;
    }
}