import React, { useEffect } from 'react';
import './loading-cash.scss';
import Spinner from 'react-bootstrap/Spinner';

export default function LoadingCash() {

    return (
        <div className="loading-cash">
            <Spinner animation="border" role="status" />
            {/* <img src="/media/icon/logo.svg" alt="CASH Assurances" /> */}
        </div>
    )
}