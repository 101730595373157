
const initialState = {
    provincesList: undefined,
    locationList: undefined,
    openID: null,
    provinceOpenID: null,
    currentLocationData: undefined,
    locationOpenName: null
}

export default function handleLocationNetworkReducer(state = initialState, action) {

    let nextState;

    switch (action.type) {
        case 'LOCATION_NETWORK_PROVINCES':
            nextState = {
                ...state,
                provincesList: action.value
            }
            return nextState || state;
        case 'LOCATION_NETWORK_LOCATION_LIST':
            nextState = {
                ...state,
                locationList: action.value
            }
            return nextState || state;
        case 'LOCATION_NETWORK_OPEN_ID':
            nextState = {
                ...state,
                openID: action.value,
                locationOpenName: state.locationList.filter(item => item.id === parseInt(action.value))[0]?.name
            }
            return nextState || state;
        case 'LOCATION_NETWORK_PROVINCE_OPEN_ID':
            nextState = {
                ...state,
                provinceOpenID: action.value
            }
            return nextState || state;
        case 'LOCATION_NETWORK_LOCATION_CURRENT_DATA':
            nextState = {
                ...state,
                currentLocationData: action.value
            }
            return nextState || state;
        default:
            return state;
    }

}