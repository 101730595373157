import React, { useState, useEffect } from 'react';
import './navbar.scss';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import NavbarLoader from './../loaders/navbar-loader';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function NavbarApp() {

    const location = useLocation();
    const [navbarData, setNavbarData] = useState(undefined);
    const [navExpanded, setNavExpanded] = useState(false);

    const getNavbar = () => {
        let prefix = "7B6BA212-C213-4674-8A01-90DDB7829A71";
        axios.get(`${process.env.REACT_APP_API_PROXY}/api/Tree/EXTERNAL_GetTree/${prefix}`).then(result => {

            let data = result.data[0].data;
            if (data) {
                setNavbarData(data);
            }

        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });;
    }

    useEffect(() => {
        getNavbar();
    }, [])

    const NavbarItem = ({ data, dropdown = false }) => {
        return (
            data.map((item, index) => (
                item.node !== undefined ?
                    <NavDropdown key={item.id} title={item.node_title} id={`basic-nav-dropdown-${index}`}>
                        <NavbarItem data={item.node} dropdown={true} />
                    </NavDropdown>
                    :
                    (
                        dropdown ?
                            (
                                item.node_link === 'SEPARATOR' ?
                                    <NavDropdown.Divider />
                                    :
                                    <NavDropdown.Item key={item.id} as={Link} to={item.node_link} disabled={item.node_link === '#soon' ? true : false}>{item.node_title}</NavDropdown.Item>
                            )
                            :
                            <Nav.Link key={item.id} as={Link} to={item.node_link} disabled={item.node_link === '#soon' ? true : false}>{item.node_title}</Nav.Link>
                    )

            ))
        )
    }

    const handleToggle = () => {
        setNavExpanded(!navExpanded);
    }

    useEffect(() => {
        setNavExpanded(false);
    }, [location])

    return (
        <>
            {
                navbarData ?
                    <div className="container-app">
                        <Navbar
                            expanded={navExpanded}
                            onToggle={handleToggle}
                            className="navbar-app" bg="light" expand="lg">
                            <Navbar.Brand as={Link} to="/">
                                <img src="/media/icon/logo-2021.png" alt="CASH Assurances" />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">
                                    {
                                        <NavbarItem data={navbarData} />
                                    }
                                </Nav>
                                <Form inline>
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            <Tooltip id={`tooltip-espace-client`}>
                                                Bientôt
                                            </Tooltip>
                                        }
                                    >
                                        <Button to="/google" variant="classic">Espace Client</Button>
                                    </OverlayTrigger>
                                </Form>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                    :
                    <NavbarLoader />
            }
        </>
    )
}