
const initialState = {
    home: {
        code_virtual_client: undefined,
        order_number: undefined,
        url_key: undefined,
        price: undefined,
        quoteType: undefined
        
    },
    enableEpayment: false
}

export default function handleEpaymentReducer(state = initialState, action) {

    let nextState;

    switch (action.type) {
        case 'UPDATE_EPAYMENT_DATA':
            nextState = {
                ...state,
                home: {
                    code_virtual_client: action.value.code_virtual_client,
                    url_key: action.value.url_key,
                    price: action.value.price,
                    order_number: action.value.order_number,
                    quoteType: action.value.quoteType
                }
            }
            return nextState || state;
        case 'TOGGLE_EPAYMENT':
            nextState = {
                ...state,
                enableEpayment: action.value
            }
            return nextState || state;
        default:
            return state;
    }
}