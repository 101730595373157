import React from 'react';
import ContentLoader from 'react-content-loader';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export default function NavbarLoader() {

    return (
        <Col sm="12" className="p-0">
            <ContentLoader
                speed={1}
                width="100%"
                height={72}
                viewBox="0 0 100% 72"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="0" y="0" rx="0" ry="0" width="100%" height="72" />
            </ContentLoader>
        </Col>

    )

}