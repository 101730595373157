import React, { useState, useEffect, useRef } from 'react';
import './contact-messenger.scss';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Editor, EditorState, Modifier } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import Scrollbars from 'react-custom-scrollbars';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import _, { result } from 'lodash';
import Spinner from 'react-bootstrap/Spinner';
//import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

export default function ContactMessenger() {

    const [show, setShow] = useState(false);
    const handleShowMessenger = (e) => setShow(!show);

    return (
        <div className="contact-messenger-container">
            {
                show && window.innerWidth > 991 &&
                <ContactMessengerBody className="popup-mode" callback={setShow} />
            }
            {
                window.innerWidth <= 991 &&
                <Modal className="modal-contact-messenger" centered size="sm" show={show} onHide={handleShowMessenger}>
                    <Modal.Header closeButton>
                        <Modal.Title>Contactez-nous</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ContactMessengerBody className="modal-mode" callback={setShow} />
                    </Modal.Body>
                </Modal>
            }
            <OverlayTrigger
                placement="left"
                overlay={
                    <Tooltip id="tooltip-messenger-handler">
                        Contactez-nous
                    </Tooltip>
                }
            >
                <Button onClick={handleShowMessenger} className={`contact-messenger-handler ${show ? "opened" : "closed"}`} variant="classic">
                    <div className="sonar-wave"></div>
                </Button>
            </OverlayTrigger>
        </div>
    )
}

function ContactEmoj({ onChange }) {

    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    return (
        <div className="contact-emoj" ref={ref}>
            <Button className variant="link" onClick={handleClick}>
            </Button>

            <Overlay
                show={show}
                target={target}
                placement="top"
                container={ref.current}
                containerPadding={20}
            >
                <Popover id="popover-contained">
                    <Popover.Content>
                        <Picker set='google' showPreview={false} onSelect={emoj => onChange(emoj.native)} />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </div>
    );
}

export function ContactMessengerBody({ callback = null, className }) {

    const data = useRef({});

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const dispatch = useDispatch();

    const { first_name, last_name, email, phone, enableSending } = useSelector(state => state.handleContactReducer);

    const handleChangeEmoji = (emoji) => {

        const newEditorState = insertCharacter(emoji, editorState);
        setEditorState(newEditorState);

    }

    const handleChange = (e) => {

        let value = e.target.value;
        let name = e.target.name;

        switch (name) {
            case 'first_name':
                dispatch({ type: "UPDATE_FIRST_NAME", value: value });
                break;
            case 'last_name':
                dispatch({ type: "UPDATE_LAST_NAME", value: value });
                break;
            case 'email':
                dispatch({ type: "UPDATE_EMAIL", value: value });
                break;
            case 'phone':
                dispatch({ type: "UPDATE_PHONE", value: value });
                break;
            default:
                break;
        }
    }

    useEffect(() => {

        let json = {};
        json["first_name"] = first_name;
        json["last_name"] = last_name;
        json["email"] = email;
        json["phone"] = phone;

        data.current = { ...data.current, ...json };

        console.log(data.current)

    }, [first_name, last_name, email, phone])

    const handleOnClickSendMessage = (e) => {

        dispatch({ type: "TOGGLE_ENABLE_SENDING", value: true });
        axios.post(`${process.env.REACT_APP_API_PROXY}/api/Contact/External_InsertContact`, data.current).then((result) => {

            let json = result.data[0];

            if (json.code === "200") {

                if (callback !== null) {
                    callback(false)
                }

                dispatch({ type: "TOGGLE_ENABLE_SENDING", value: false });
                setEditorState(() => EditorState.createEmpty());

                dispatch({ type: 'NEW_NOTIFICATION', value: { showNotification: true, status: "success", message: json.message } });
            } else {
                dispatch({ type: 'NEW_NOTIFICATION', value: { showNotification: true, status: "error", message: json.message } });
            }

        }).catch(function (error) {

            dispatch({ type: "TOGGLE_ENABLE_SENDING", value: false });

            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        });
    }

    const insertCharacter = (characterToInsert, editorState) => {

        const currentContent = editorState.getCurrentContent();
        const currentSelection = editorState.getSelection();

        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            characterToInsert
        );

        const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');

        return newEditorState;
    }

    useEffect(() => {
        if (editorState !== null && editorState !== undefined) {

            let html = stateToHTML(editorState.getCurrentContent());
            let json = {};
            json["message"] = html;

            data.current = { ...data.current, ...json };
        }
    }, [editorState])

    return (
        <div className={`contact-messenger ${className || ""}`}>
            <Card>
                <Card.Header>
                    <Form.Control onChange={handleChange} name="email" type="email" placeholder="@ Laissez nous votre email" className="border-none" />
                    <Form.Control onChange={handleChange} name="phone" type="text" placeholder="Téléphone" className="br-0 border-none" />
                    <InputGroup>
                        <Form.Control onChange={handleChange} name="first_name" type="text" placeholder="Prenom" />
                        <Form.Control onChange={handleChange} name="last_name" type="text" placeholder="Nom" />
                    </InputGroup>
                </Card.Header>
                <Card.Body>
                    <Scrollbars
                        autoHide
                        autoHideTimeout={200}
                        style={{ height: '100%', position: 'relative' }}
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                    >
                        <Col sm="12">
                            <Editor placeholder="Ecrivez nous !" editorState={editorState} onChange={setEditorState} />
                        </Col>
                    </Scrollbars>
                </Card.Body>
                <Card.Footer>
                    <Button onClick={handleOnClickSendMessage} variant="classic" size="sm" className="d-flex align-items-center" disabled={enableSending}>
                        Envoyer
                                {
                            enableSending &&
                            <Spinner className="ml-2" animation="border" size="sm" />
                        }
                    </Button>
                    <ContactEmoj onChange={handleChangeEmoji} />
                </Card.Footer>
            </Card>
        </div>

    )
}