import React, { useState, Suspense } from 'react';
import './dist/sass/custom-bootstrap.scss';
import './dist/sass/app.scss';
import { Provider } from 'react-redux';
import Store from './store/configureStore';
import { BrowserRouter, Route } from 'react-router-dom';
import dotenv from 'dotenv';
import LoadingCash from './components/loading-cash/loading-cash';
import Layout from './components/layout/layout';
import OurIdentity from './pages/qui-sommes-nous/beta/beta';

const env = process.env.NODE_ENV || 'development';
dotenv.config({ path: `.env.${env}` });

export default function App() {

  const [routes] = useState([
    { id: 1, path: "/", importPath: "./pages/home/home.js", exact: true },
    { id: 2, path: "/qui-sommes-nous", importPath: "./pages/qui-sommes-nous/beta/beta.js" },
    { id: 3, path: "/assurances/:param2?/:param3?/:param4?", importPath: "./pages/insurance/insurance.js" },
    { id: 4, path: "/articles/:param2?", importPath: "./pages/articles/articles.js" },
    { id: 5, path: "/telechargements/:prefix", importPath: "./pages/book/book.js" },
    { id: 6, path: "/recrutement/:param2?", importPath: "./pages/recruitement/recruitement.js" },
    { id: 7, path: "/faq", importPath: "./pages/faq/faq.js" },
    { id: 8, path: "/notre-reseau", importPath: "./pages/location/location.js" },
    { id: 9, path: "/devis-multirisques-habitation/:key?", importPath: "./pages/quote/quote-home/quote-home.js" },
    { id: 10, path: "/devis-catastrophes-naturelles/:key?", importPath: "./pages/quote/quote-disaster/quote-disaster.js" },
    { id: 11, path: "/devis-automobile/:key?", importPath: "./pages/quote/quote-car/quote-car.js"},
    { id: 12, path: "/epayment-confirm-order/:key?", importPath: "./pages/epayment-confirm-order/epayment-confirm-order.js" },
    { id: 13, path: "/epayment-error", importPath: "./pages/epayment-error/epayment-error.js" },
    { id: 14, path: "/epayment-contract/:codeQuoteType/:key", importPath: "./pages/epayment-contract/epayment-contract.js" }
    /*
    { id: 15, path: "/login", importPath: "./pages/login/login.js" },
    ,*/
    
  ]);


  return (
    <Provider store={Store}>
      <BrowserRouter>
        <Layout>
          {
            routes.map(route => {

              const ProfilePage = React.lazy(() => import(`${route.importPath}`));
              return (
                <Route key={route.id} path={route.path} {...(route.exact ? { exact: true } : undefined)}>
                  <Suspense fallback={<LoadingCash />}>
                    <ProfilePage />
                  </Suspense>
                </Route>
              )
            })
          }
        </Layout>
      </BrowserRouter>
    </Provider>
  )

}