import React, { useState, useEffect } from 'react'
import './notifications.scss';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

export default function Notifications() {

    const dispatch = useDispatch();
    const { showNotification, title, status, message } = useSelector(state => state.handleNotificationsReducer);

    const handleClose = () => dispatch({ type: 'RESET_NOTIFICATION' });

    return (
        <Modal className="notifications-modal" centered backdrop="static" show={showNotification} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {status !== undefined && <span className={`icon icon-${status}`}></span>}
                    {title || "Notification"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message || "Message not found !"}
            </Modal.Body>
        </Modal>
    );
}