import React, { useState, useEffect, useRef } from 'react';
import './quote-call.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useLocation } from 'react-router-dom';

export default function QuoteCall() {

    const location = useLocation();
    const [show, setShow] = useState(false);

    useEffect(() => {
        let pathname = location.pathname;
        if (location !== "/") {
            pathname = pathname.split("/");
            if (["devis-automobile", "devis-multirisques-habitation", "devis-catastrophes-naturelles"].includes(pathname[1])) {
                setShow(false);
            } else {
                setShow(true);
            }
        }
    }, [location])

    return (
        <>
            {
                show ?
                    <div className="quote-call">
                        < Dropdown drop="top" >
                            <Dropdown.Toggle variant="classic" id="dropdown-basic">
                                <span className="cib"></span>
                                Souscrire maintenant
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/devis-multirisques-habitation">Multirisques Habitation</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/devis-catastrophes-naturelles">Catastrophes Naturelles</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown >
                    </div >
                    :
                    <></>
            }
        </>

    )
}