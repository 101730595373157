
const initialState = {
    costs: undefined,
    details: undefined
}

export default function handleQuoteDetailsReducer(state = initialState, action) {

    let nextState;

    switch (action.type) {
        case 'QUOTE_DETAILS_UPDATE':
            nextState = {
                ...state,
                costs: action.value.costs,
                details: action.value.details
            }
            return nextState || state;
        default:
            return state;
    }

}