import React, { useState, useEffect } from 'react';
import './advertising-toast.scss';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

export default function AdvertisingToast({ data }) {

    const [transparency, setTransparency] = useState(false);
    const [show, setShow] = useState(true);

    const toggleShow = () => setShow(!show);

    const getType = (mediaUrl) => {

        if (mediaUrl.toLowerCase().indexOf("youtube") !== -1 || mediaUrl.toLowerCase().indexOf("youtu.be") !== -1) {
            return "youtube";
        } else {
            return "image"
        }
    }

    useEffect(() => {

        document.addEventListener("scroll", (event) => {
            let scroll = window.scrollY;
            if (scroll > 250) {
                setTransparency(true)
            } else {
                setTransparency(false);
            }
        });

    }, [])

    useEffect(() => {
        console.log(data)
    }, [data])

    return (

        show ?
            <div className={`advertising-toast ${transparency ? "transparent" : ""}`}>
                <Toast show={show} onClose={toggleShow}>
                    <Toast.Header>
                        <strong className="mr-auto"></strong>
                    </Toast.Header>
                    <Toast.Body>
                        <h3>{data.title}</h3>
                        {
                            data.media_url &&
                            getType(data.media_url) === "image" &&
                            <img src={data.media_url} alt={data.title} />
                        }
                        {
                            data.media_url &&
                            getType(data.media_url) === "youtube" &&
                            <iframe width="100%"  src={data.media_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        }

                        <div className="toast-container">

                            {
                                data.description &&
                                <p>{data.description}</p>
                            }
                            {
                                data.link_tags &&
                                (
                                    data.link_tags.length > 1 ?
                                    <Dropdown drop="top" className="mt-2">
                                            <Dropdown.Toggle variant="classic" id="dropdown-basic">
                                                <span className="cib"></span>
                                                {data.link_text}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {
                                                    data.link_tags.map((item, index) => (

                                                        <Dropdown.Item key={index} as={Link} to={item.value}>{item.text}</Dropdown.Item>

                                                    ))
                                                }
                                            </Dropdown.Menu>
                                    </Dropdown >
                                    :
                                    <Button className="mt-2" as={Link} to={data.link_tags[0].value} size="sm" variant="outline-classic">
                                        {data.link_text}
                                    </Button>
                                )
                                
                            }
                        </div>
                    </Toast.Body>
                </Toast>
            </div>
            :
            <></>

    )
}