
const initialState = {
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    phone: undefined,
    enableSending: false
}

export default function handleContactReducer(state = initialState, action) {

    let nextState;

    switch (action.type) {
        case 'UPDATE_FIRST_NAME':
            nextState = {
                ...state,
                first_name: action.value
            }
            return nextState || state;
        case 'UPDATE_LAST_NAME':
            nextState = {
                ...state,
                last_name: action.value
            }
            return nextState || state;
        case 'UPDATE_EMAIL':
            nextState = {
                ...state,
                email: action.value
            }
            return nextState || state;
        case 'UPDATE_PHONE':
            nextState = {
                ...state,
                phone: action.value
            }
            return nextState || state;
        case 'TOGGLE_ENABLE_SENDING':
            nextState = {
                ...state,
                enableSending: action.value
            }
            return nextState || state;
        default:
            return state;
    }
}