
const initialState = {
    faq: undefined,
    openID: undefined,
}

export default function handleFaqReducer(state = initialState, action) {

    let nextState;

    switch (action.type) {
        case 'FAQ_UPDATE':
            nextState = {
                ...state,
                faq: action.value
            }
            return nextState || state;
        case 'FAQ_OPEN_ID_UPDATE':
            nextState = {
                ...state,
                openID: action.value
            }
            return nextState || state;
        default:
            return state;
    }

}