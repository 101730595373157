import { createStore, combineReducers } from 'redux';
import handleUiReducer from './reducers/ui_reducer';
import handleLunaFormsReducer from './reducers/luna_forms_reducer';
import handleQuoteHomeReducer from './reducers/quote_home_reducer';
import handleQuoteDetailsReducer from './reducers/quote_details_reducer';
import handleQuoteDisasterReducer from './reducers/quote_disaster_reduce';
import handleFaqReducer from './reducers/faq_reducer';
import handleLocationNetworkReducer from './reducers/location_network_reducer';
import handleEpaymentReducer from './reducers/epayment_reducer';
import handleNotificationsReducer from './reducers/notifications_reducer';
import handleAdvertisingReducer from './reducers/advertising_reducer';
import handleContactReducer from './reducers/contact_reducer';
import handleQuoteCarReducer from './reducers/quote_car_reducer';

export default createStore(combineReducers({
    handleUiReducer,
    handleLunaFormsReducer,
    handleQuoteHomeReducer,
    handleQuoteDetailsReducer,
    handleQuoteDisasterReducer,
    handleFaqReducer,
    handleLocationNetworkReducer,
    handleEpaymentReducer,
    handleNotificationsReducer,
    handleAdvertisingReducer,
    handleContactReducer,
    handleQuoteCarReducer
}));